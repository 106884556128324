import { api } from '.';
import { OFFER_RESOURCE, POST_EVENT_RESOURCE } from '@/services/keys';

export const getResources = offerId => ({
  [OFFER_RESOURCE]: offerId,
  [POST_EVENT_RESOURCE]: undefined,
});

/**
 * @param {Offer} offer
 * @param {PostEvent} postEvent
 * @returns {Promise.<string>}
 */

export async function createPostEvent(offer, postEvent) {
  const partialUrl = api.createUrl(getResources(offer.id));
  const {
    data: {
      OfferPostEvent: { id },
    },
  } = await api.create(partialUrl, postEvent.payload());

  return id;
}
