import { api } from '.';
import { POST_EVENT_RESOURCE, OFFER_RESOURCE } from '@/services/keys';

export const getResources = (offerId, postEventTemplateId) => ({
  [OFFER_RESOURCE]: offerId,
  [POST_EVENT_RESOURCE]: postEventTemplateId,
});

/**
 * @param {PostEvent} postEvent
 * @param {Offer} offer
 * @returns {Promise.<string>}
 */

export async function deletePostEvent(offer, postEvent) {
  const partialUrl = api.createUrl(getResources(offer.id, postEvent.id));
  await api.delete(partialUrl);
  return postEvent.id;
}
