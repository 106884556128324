import { api } from '.';
import { OFFER_RESOURCE, POST_EVENT_STATUS_RESOURCE } from '@/services/keys';

export const getResources = offerId => ({
  [OFFER_RESOURCE]: offerId,
  [POST_EVENT_STATUS_RESOURCE]: null,
});

/**
 *
 * @param {Offer} offer
 * @param {Object} postEventStatus
 * @returns {Promise<object>}
 */
export async function updatePostEventStatus(offer, postEventStatus) {
  const partialUrl = api.createUrl(getResources(offer.id));
  return await api.update(partialUrl, postEventStatus);
}
