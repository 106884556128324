import { render, staticRenderFns } from "./PostEvent.vue?vue&type=template&id=2a6a2dfc&scoped=true&"
import script from "./PostEvent.vue?vue&type=script&lang=js&"
export * from "./PostEvent.vue?vue&type=script&lang=js&"
import style0 from "./PostEvent.vue?vue&type=style&index=0&id=2a6a2dfc&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a6a2dfc",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/gitlab-runner/builds/D6nERZR5/0/sunmedia/asterix/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2a6a2dfc')) {
      api.createRecord('2a6a2dfc', component.options)
    } else {
      api.reload('2a6a2dfc', component.options)
    }
    module.hot.accept("./PostEvent.vue?vue&type=template&id=2a6a2dfc&scoped=true&", function () {
      api.rerender('2a6a2dfc', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/organisms/modules/ecommerce/offer/form/postEvent/PostEvent.vue"
export default component.exports