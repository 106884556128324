import { getPostEventTemplates } from '../../postEventTemplate/getPostEventTemplates';
import { deletePostEvent } from './deletePostEvent';
import { createPostEvent } from './createPostEvent';
import { updatePostEvent } from './updatePostEvent';
import { updatePostEventStatus } from './updatePostEventStatus';
import { ecommerce } from '../..';

export const api = ecommerce;

export { getPostEventTemplates, deletePostEvent, createPostEvent, updatePostEvent, updatePostEventStatus };
