import { api } from '.';
import { OFFER_RESOURCE, POST_EVENT_RESOURCE } from '@/services/keys';

export const getResources = (offerId, postEventId) => ({
  [OFFER_RESOURCE]: offerId,
  [POST_EVENT_RESOURCE]: postEventId,
});

/**
 *
 * @param {Offer} offer
 * @param {PostEvent} postEvent
 * @returns {Promise<object>}
 */
export async function updatePostEvent(offer, postEvent) {
  const partialUrl = api.createUrl(getResources(offer.id, postEvent.id));
  return await api.update(partialUrl, postEvent.payload());
}
