<template>
  <div>
    <div v-show="!isLoadingContent && postEventTemplates">
      <card-form>
        <template slot="title">Post Event</template>
        <template v-if="offer" slot="form">
          <form-row>
            <template #left>
              <div class="flex">
                <sun-toggle
                  v-model="offer.postEventStatus"
                  name="active-post-events-toggle"
                  :disabled="!postEventTemplates.length || isUpdating"
                  checked-value="ACTIVE"
                  unchecked-value="INACTIVE"
                  @change="postEventStatusChange()"
                />
                <span class="text-sm font-bold text-gray-700 ml-5 whitespace-no-wrap">Active Postevents</span>
              </div>
            </template>
            <template #right>
              <div class="flex justify-end items-center">
                <p v-if="!postEventTemplates.length" class="italic text-sm mr-4">
                  There isn't any post event template yet
                </p>

                <asterix-dropdown-multiple
                  v-model="postEventTemplates"
                  :disabled="!activePostEvents || !postEventTemplates.length"
                  @change="changePostEventTemplate"
                >
                  + Add Post Event
                </asterix-dropdown-multiple>
              </div>
            </template>
          </form-row>
          <template v-if="activePostEvents && !isUpdating">
            <form-row v-for="(postEvent, index) in offer.postEvents" :key="postEvent.id">
              <sun-form class="block w-full border rounded">
                <div class="flex flex-col hover:bg-gray-200 p-3 lg:flex-row relative">
                  <sun-popover v-if="postEvent.changed && !isDuplicate" text-info="Post Event not saved">
                    <warning-svg class="w-4 text-orange-500" />
                  </sun-popover>
                  <div class="flex flex-1 lg:mr-4 lg:items-center justify-between">
                    <label class="flex items-center mx-2">
                      <span
                        class="font-semibold text-gray-700 text-sm min-w-40 truncate"
                        :title="postEvent.postEventTemplate.name"
                      >
                        {{ postEvent.postEventTemplate.name }}
                      </span>
                    </label>
                  </div>
                  <div id="price-wrapper" class="flex mt-4 lg:mt-0 w-full">
                    <sun-label-group text="Unique" class="w-full md:mr-2">
                      <sun-select
                        :value="getUniqueConversion(postEvent)"
                        :options="uniqueConversionOptions"
                        label="name"
                        track-by="value"
                        close-on-select
                        @input="uniqueChange(index, $event)"
                      >
                      </sun-select>
                    </sun-label-group>
                    <div v-if="isCostPriceType" class="flex flex-col md:flex-row w-full">
                      <sun-label-group text="Gross Price" class="w-full md:mr-2">
                        <currency-selector
                          :value="postEvent.grossPrice"
                          class="w-full"
                          placeholder="0.00"
                          :max-decimals="maxDecimals"
                          :default-currency="offerCurrency"
                          currency-disabled
                          @input="changeStatusGross(index, $event)"
                        />
                      </sun-label-group>
                      <sun-label-group text="Net Price" class="w-full mt-2 md:mt-0">
                        <currency-selector
                          :value="postEvent.netPrice"
                          class="w-full"
                          placeholder="0.00"
                          :max-decimals="maxDecimals"
                          :default-currency="offerCurrency"
                          currency-disabled
                          @input="changeStatusNet(index, $event)"
                        />
                      </sun-label-group>
                    </div>
                    <div v-else class="flex flex-col md:flex-row w-full">
                      <sun-label-group text="Gross Price" class="w-full md:mr-2 relative">
                        <asterix-input
                          :value="postEvent.grossPrice.value"
                          placeholder="0.00"
                          type="number"
                          :maxlength="getPercentageMaxDecimalsLength(postEvent.grossPrice.value)"
                          :maxlength-error="maxlengthPercentageError"
                          @input="changeStatusGross(index, { value: $event })"
                        />
                        <div class="absolute right-0 flex items-center w-16 h-10 mr-2">
                          <span class="text-sm mx-auto text-gray-600">%</span>
                        </div>
                      </sun-label-group>
                      <sun-label-group text="Net Price" class="w-full mt-2 md:mt-0 relative">
                        <asterix-input
                          :value="postEvent.netPrice.value"
                          placeholder="0.00"
                          type="number"
                          :maxlength="getPercentageMaxDecimalsLength(postEvent.netPrice.value)"
                          :maxlength-error="maxlengthPercentageError"
                          @input="changeStatusNet(index, { value: $event })"
                        />
                        <div class="absolute right-0 flex items-center w-16 h-10 mr-2">
                          <span class="text-sm mx-auto text-gray-600">%</span>
                        </div>
                      </sun-label-group>
                    </div>
                  </div>
                  <div class="flex flex-1 justify-end mt-2 lg:mt-0 lg:items-center">
                    <button
                      class="flex justify-center text-gray-700 ml-3 h-8 w-8 cursor-pointer hover:bg-gray-400 hover:text-gray-800 rounded-full items-center outline-hidden"
                      @click="deleteOfferPostEvent(postEvent)"
                    >
                      <trash-svg class="w-5 py-auto px-auto" />
                    </button>
                    <button
                      :disabled="!postEvent.code"
                      class="flex justify-center text-gray-700 ml-3 h-8 w-8 cursor-pointer hover:bg-gray-400 hover:text-gray-800 rounded-full items-center outline-hidden"
                      @click="openDialog(postEvent)"
                    >
                      <eye-svg class="w-5 py-auto px-auto" :class="{ 'text-gray-400': !postEvent.code }" />
                    </button>
                    <button
                      v-if="!isDuplicate"
                      class="flex justify-center text-gray-500 mx-3 h-8 w-8 rounded-full items-center outline-hidden"
                      :class="{
                        'cursor-pointer hover:bg-gray-400 hover:text-gray-800 text-gray-700': postEvent.changed,
                      }"
                      @click="savePostEvent(postEvent)"
                    >
                      <save-svg class="w-4 py-auto px-auto" />
                    </button>
                  </div>
                </div>
              </sun-form>
            </form-row>
          </template>
          <template v-if="(isUpdating && activePostEvents) || !baseOffer">
            <post-event-template-loader v-for="index in 4" :key="index" class="my-5"></post-event-template-loader>
          </template>
        </template>
      </card-form>
    </div>

    <asterix-modal v-if="showPixelModal" title="Postevent Pixel">
      <template #content>
        <div class="flex flex-col w-full border-b border-gray-300">
          <div class="text-gray-600 font-bold text-l">Image</div>
          <div class="mb-8 mx-2 w-full pr-2">
            <asterix-input-and-copy title="Offer" :value="offerLinkTag" @click:input="openPixelModal" />
            <asterix-input-and-copy title="Campaign" :value="campaignLinkTag" @click:input="openPixelModal" />
          </div>
        </div>
        <div class="flex flex-col w-full mt-4">
          <div class="text-gray-600 font-bold text-l">Javascript</div>
          <div class="mb-8 mx-2 w-full pr-2">
            <asterix-input-and-copy title="Offer" :value="offerLinkTagJS" @click:input="openPixelModal" />
            <asterix-input-and-copy title="Campaign" :value="campaignLinkTagJS" @click:input="openPixelModal" />
          </div>
        </div>
      </template>
      <template #footer>
        <div class="flex flex-wrap justify-end between sm:px-3">
          <sun-button
            variant="pill"
            color="white"
            class="custom-p-1 text-sm hover:bg-gray-100"
            @click="showPixelModal = false"
          >
            Close
          </sun-button>
        </div>
      </template>
    </asterix-modal>

    <copy-pixel-modal v-if="entityPixelToShow" :value="entityPixelToShow" @cancel="entityPixelToShow = null" />

    <delete-modal :open="showDeleteModal" @cancel="showDeleteModal = false" @confirm="deleteElement">
      <template #description> {{ deleteModalDescription }}</template>
    </delete-modal>

    <div v-if="isLoadingContent || !postEventTemplates"><card-form-loading /></div>
    <div v-if="isDuplicate && !isLoadingContent" class="flex mt-12">
      <save-button id="offer-submit" text="Duplicate" :loading="isLoading" @click="duplicateOffer()" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { ACTIVE_CLIENT } from '@/store/modules/context/keys';
import { CREATE_TOAST } from '@/store/modules/toast/keys';
import { Toast } from '@/model/shared/Toast';
import CardForm from '@/components/atoms/CardForm';
import metaInfo from '@/mixins/common/metaInfo';
import { EcommerceApi } from '@/services/api/EcommerceApi';
import PostEvent from '@/entities/ecommerce/PostEvent';
import CardFormLoading from '@/components/atoms/CardFormLoading';
import FormRow from '@/components/atoms/FormRow/FormRow';
import TrashSvg from '@/components/icons/TrashSvg';
import WarningSvg from '@/components/icons/WarningSvg';
import SaveSvg from '@/components/icons/SaveSvg';
import EyeSvg from '@/components/icons/EyeSvg';
import AsterixDropdownMultiple from '@/components/molecules/shared/AsterixDropdownMultiple';
import { deepClone } from '@/utils/deepClone';
import { POST_EVENT_RESOURCE } from '@/services/keys';
import CurrencySelector from '@/components/atoms/CurrencySelector/CurrencySelector';
import { copyToClipboard } from '@/utils/copyToClipboard';
import {
  getPostEventTemplates,
  updatePostEvent,
  createPostEvent,
  deletePostEvent,
  updatePostEventStatus,
} from '@/services/modules/ecommerce/offer/postEventTemplate';
import CONFIG from '@/components/organisms/modules/ecommerce/offer/form/config.js';
import Tab from '@/model/shared/Tab';
import QueryParamsBuilder from '@/model/shared/QueryParamsBuilder';
import { HTTPStatusCode } from '@/model/shared/HTTPStatusCode';
import PostEventTemplateLoader from '@/components/atoms/Loaders/PostEventTemplateLoader';
import { SET_LOADING_TOAST, SET_STATE_ERROR_TOAST, SET_STATE_SUCCESS_TOAST } from '@/store/modules/loadingToast/keys';
import { LOADING_TOAST_STATUS } from '@/model/shared/LoadingToastStatus';
import { LoadingToast } from '@/entities/shared/LoadingToast';
import apiRequest from '@/utils/apiRequest';
import SaveButton from '@/components/atoms/SaveButton';
import createPixelTag from '@/utils/createPixelTag';
import createPixelJSTag from '@/utils/createPixelJSTag';
import AsterixInput from '@/components/atoms/AsterixInput';
import COST_VALUE_TYPES from '@/model/modules/ecommerce/offer/costValueTypes';
import { UNIQUE_CONVERSION_OPTIONS } from '@/model/modules/ecommerce/offer/TypePixelOptions';

export default {
  name: 'PostEvent',
  components: {
    CardForm,
    WarningSvg,
    FormRow,
    CardFormLoading,
    TrashSvg,
    CurrencySelector,
    SaveSvg,
    AsterixDropdownMultiple,
    EyeSvg,
    PostEventTemplateLoader,
    SaveButton,
    AsterixInput,
    AsterixModal: () => import('@/components/organisms/shared/AsterixModal'),
    DeleteModal: () => import('@/components/organisms/shared/DeleteModal'),
    CopyPixelModal: () => import('@/components/organisms/modules/ecommerce/CopyPixelModal'),
    AsterixInputAndCopy: () => import('@/components/atoms/AsterixInputAndCopy'),
  },
  mixins: [metaInfo],
  props: {
    /** @type Offer */
    baseOffer: {
      type: Object,
      default: () => null,
    },
    viewMode: {
      type: String,
      default: () => null,
    },
    updatedOffer: {
      type: Object,
      default: () => null,
    },
    isLoading: {
      type: Boolean,
      default: () => false,
    },
  },
  data: () => ({
    isLoadingContent: true,
    isUpdating: false,
    hasError: false,
    formErrors: {
      name: '',
    },
    currentApi: new EcommerceApi(),
    resource: POST_EVENT_RESOURCE,
    showPixelModal: false,
    currenciesFormatted: [],
    showDeleteModal: false,
    deleteModalDescription: null,
    offerPostEvents: [],
    postEventTemplates: [],
    pixelOfSelectedEvent: null,
    itemToDelete: null,
    offer: null,
    entityPixelToShow: null,
    costValueTypes: COST_VALUE_TYPES,
    maxDecimals: 6,
    percentageMaxDecimals: 4,
    uniqueConversionOptions: UNIQUE_CONVERSION_OPTIONS,
  }),
  computed: {
    ...mapGetters({ activeClient: ACTIVE_CLIENT }),
    activePostEvents() {
      return this.offer.postEventStatus === 'ACTIVE';
    },
    isDuplicate() {
      return this.viewMode === 'duplicate';
    },
    offerLinkTag() {
      return this.pixelOfSelectedEvent ? createPixelTag(this.pixelOfSelectedEvent.offerLink) : null;
    },
    campaignLinkTag() {
      return this.pixelOfSelectedEvent ? createPixelTag(this.pixelOfSelectedEvent.campaignLink) : null;
    },
    offerLinkTagJS() {
      return this.pixelOfSelectedEvent ? createPixelJSTag(this.pixelOfSelectedEvent.offerLink) : null;
    },
    campaignLinkTagJS() {
      return this.pixelOfSelectedEvent ? createPixelJSTag(this.pixelOfSelectedEvent.campaignLink) : null;
    },
    isCostPriceType() {
      return this.offer.priceValueType === this.costValueTypes.PRICE;
    },
    offerCurrency() {
      return this.offer.currency.id;
    },
    maxlengthPercentageError() {
      return `${this.percentageMaxDecimals} decimals max`;
    },
    postEventsHaveChanges() {
      if (!this.offer) return null;
      return this.offer.postEvents.some(postEvent => postEvent.changed);
    },
  },
  watch: {
    baseOffer() {
      this.loadOffer();
    },
    offer: {
      deep: true,
      handler(newOffer, previous) {
        if (!previous) return null;
        this.checkAdded();
        this.updateOffer(newOffer);
      },
    },
    postEventsHaveChanges() {
      this.changeTabStatus();
    },
  },
  async created() {
    this.isLoadingContent = true;
    this.loadOffer();
    await this.getPostEventTemplates();
    this.isLoadingContent = false;
  },
  methods: {
    ...mapActions({
      createToast: CREATE_TOAST,
      setPropsLoadingToast: SET_LOADING_TOAST,
      setSuccessLoadingToast: SET_STATE_SUCCESS_TOAST,
      setErrorLoadingToast: SET_STATE_ERROR_TOAST,
    }),
    copyToClipboard,
    loadOffer() {
      this.offer = deepClone(this.updatedOffer);
    },
    changeStatusGross(index, event) {
      if (this.offer) {
        this.offer.postEvents[index].changed = true;
        this.offer.postEvents[index].grossPrice = { value: event.value };
      }
    },
    changeStatusNet(index, event) {
      if (this.offer) {
        this.offer.postEvents[index].changed = true;
        this.offer.postEvents[index].netPrice = { value: event.value };
      }
    },
    changeStatus(index, event) {
      if (this.offer) {
        this.offer.postEvents[index].changed = true;
        this.offer.postEvents[index].money = { value: event.value };
      }
    },
    changeTabStatus() {
      if (!this.isDuplicate)
        this.$emit(
          'tab-update',
          new Tab(CONFIG.tabNames.postEvent, CONFIG.tabRouteEdit.postEvent, this.postEventsHaveChanges)
        );
    },
    uniqueChange(index, uniqueConversion) {
      this.offer.postEvents[index].changed = true;
      this.offer.postEvents[index].unique = uniqueConversion.value;
    },
    getUniqueConversion(postEvent) {
      return this.uniqueConversionOptions.find(({ value }) => value === postEvent.unique);
    },
    openDialog({ code }) {
      this.pixelOfSelectedEvent = code;
      this.showPixelModal = true;
    },
    openPixelModal(pixel) {
      this.entityPixelToShow = { name: 'Post Event pixel', pixel };
    },
    changePostEventTemplate(item) {
      const event = this.offer.postEvents.find(event => event.postEventTemplate?.id === item.id);
      if (!event) {
        const postEvent = new PostEvent(null, item.unique, item.netPrice, item.grossPrice, item.code, item.id);
        postEvent.postEventTemplate = { name: item.name, id: item.id };
        postEvent.changed = true;
        this.offer.postEvents.push(postEvent);
      }
    },
    async getPostEventTemplates() {
      try {
        const params = new QueryParamsBuilder();
        const { data } = await getPostEventTemplates(params, this.activeClient.id);
        this.postEventTemplates = data.map(event => {
          event.selected = !!this.offer?.postEvents?.find(offerEvent => offerEvent.templateId === event.id);
          return event;
        });
      } catch (error) {
        this.hasError = error.code !== HTTPStatusCode.Cancel;
      }
    },
    checkAdded() {
      this.postEventTemplates = this.postEventTemplates.map(event => {
        event.selected = !!this.offer?.postEvents?.find(offerEvent => offerEvent.templateId === event.id);
        return event;
      });
    },
    deleteOfferPostEvent(postEvent) {
      this.isLoadingContent = true;
      if (postEvent.id) {
        this.deleteModalDescription = `Post Event "${postEvent.postEventTemplate.name}" is going to be deleted.`;
        this.showDeleteModal = true;
        this.itemToDelete = postEvent;
      } else {
        const index = this.offer.postEvents.findIndex(
          event => postEvent.postEventTemplate.id === event.postEventTemplate.id
        );
        this.offer.postEvents.splice(index, 1);
        this.removeItemInDropwdown(postEvent.postEventTemplate?.id);
      }
      this.isLoadingContent = false;
    },
    removeItemInDropwdown(templateId) {
      const exists = false;
      this.postEventTemplates.forEach(event => {
        if (event.id === templateId) {
          this.$set(event, 'selected', false);
        }
      });
      return exists;
    },
    async deleteElement() {
      const templateId = this.itemToDelete?.postEventTemplate?.id;
      if (this.isDuplicate) {
        const index = this.offer.postEvents.findIndex(event => templateId === event.postEventTemplate.id);
        this.offer.postEvents.splice(index, 1);
        this.removeItemInDropwdown(templateId);
        this.itemToDelete = null;
        this.showDeleteModal = false;
      } else {
        this.isLoadingContent = true;
        try {
          await deletePostEvent(this.baseOffer, this.itemToDelete);
          this.createToast(Toast.success('Post Event deleted.', 'Post Event was deleted successfully.'));
          const index = this.offer.postEvents.findIndex(event => templateId === event.postEventTemplate.id);
          this.offer.postEvents.splice(index, 1);
          this.removeItemInDropwdown(templateId);
          this.itemToDelete = null;
          this.showDeleteModal = false;
          this.reloadOffer();
        } catch (e) {
          this.createToast(Toast.error('Post Event not deleted', e.message));
        }
        this.isLoadingContent = false;
      }
    },
    async savePostEvent(postEvent) {
      if (!postEvent?.changed) {
        return false;
      }
      this.isUpdating = true;
      if (postEvent.id) {
        try {
          const event = new PostEvent(
            postEvent.id,
            postEvent.unique,
            postEvent.netPrice,
            postEvent.grossPrice,
            postEvent.code,
            postEvent.templateId
          );
          await updatePostEvent(this.baseOffer, event);
          this.reloadOffer();
          this.createToast(Toast.success('Post Event updated.', 'Post Event was updated successfully.'));
        } catch (e) {
          this.createToast(Toast.error('Post Event not updated', e.message));
        }
      } else {
        try {
          const event = new PostEvent(
            null,
            postEvent.unique,
            postEvent.netPrice,
            postEvent.grossPrice,
            postEvent.code,
            postEvent.postEventTemplate.id
          );
          const id = await createPostEvent(this.baseOffer, event);
          this.offer.postEvents.forEach((event, index) => {
            if (event.postEventTemplate.id === postEvent.postEventTemplate.id) {
              this.$set(this.offer.postEvents[index], 'id', id);
            }
          });
          this.reloadOffer();
          this.createToast(Toast.success('Post Event created.', 'Post Event was created successfully.'));
        } catch (e) {
          this.createToast(Toast.error('Post Event not created', e.message));
        }
      }
      postEvent.changed = false;
      this.$emit('update-post-events');
      this.isUpdating = false;
    },
    async postEventStatusChange() {
      if (!this.isDuplicate) {
        const loadingToast = new LoadingToast('link', {}, false, false, LOADING_TOAST_STATUS.LOADING);
        loadingToast.textSuccess = 'Post events status update succesfully';
        loadingToast.textLoading = 'Updating post events status';
        loadingToast.textError = 'Error updating post event Status';
        this.setPropsLoadingToast(loadingToast);
        this.isUpdating = true;
        await apiRequest(async () => {
          await updatePostEventStatus(this.offer, { status: this.offer.postEventStatus });
          this.setSuccessLoadingToast();
          this.reloadOffer();
        }).catch(() => {
          this.setErrorLoadingToast();
          const previousValue = this.offer.postEventStatus === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE';
          this.$set(this.offer, 'postEventStatus', previousValue);
        });
        this.isUpdating = false;
      }
    },
    reloadOffer() {
      this.$emit('reload');
    },
    updateOffer(offer) {
      this.$emit('update-offer', offer);
    },
    duplicateOffer() {
      this.$emit('duplicate', this.offer);
    },
    getPercentageMaxDecimalsLength(value) {
      if (value?.toString().includes('.')) {
        const index = value.toString().indexOf('.');
        return index + this.percentageMaxDecimals + 1;
      }
      return null;
    },
  },
};
</script>

<style scoped>
.list-item {
  @apply flex flex-wrap border-b p-4;
}
.list-item-sm {
  @apply flex-no-wrap;
}
.outline-hidden {
  outline: none;
}
#price-wrapper >>> .sun-label {
  @apply font-semibold text-gray-600 truncate;
}
.min-w-40 {
  min-width: 5rem;
}
</style>
